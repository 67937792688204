import React from 'react';

import Layout from '../components/layout';
import SeoComponent from '../components/seo';
import {Link} from "gatsby";
const NotFoundPage = ({ location }) => (
  <Layout location={location}>
    <SeoComponent title="404: Page Not found" />
    <div className="grid-container padding-top-10 padding-bottom-10 text-center">
      <h1>We're sorry!</h1>
      <p>There was an error with the page you requested.</p>
      <Link to="/" className="usa-button primary-button padding-x-5">BACK TO HOMEPAGE</Link>
    </div>
  </Layout>
);

export default NotFoundPage;
